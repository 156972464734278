import {
  Button,
  CrossIcon,
  Heading,
  IconButton,
  Pane,
  Text,
} from 'evergreen-ui';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import {
  findOldestAndMostRecentDates,
  parseStatementDateToDisplay,
  parseStatementDateToFetch,
} from '../../helpers/ofxHelper';
import { parseOFXToJSON } from './ofxParser';
import { useDispatch, useSelector } from 'react-redux';
import {
  putManyEntry,
  useQueryEntriesReconciliation,
} from '../../services/api/entriesService';
import { useEffect } from 'react';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import { mutate } from 'swr';
import {
  postStatement,
  putStatement,
  useQueryStatements,
} from '../../services/api/statementService';
import { useQueryBanks } from '../../services/api/banksService';
import ReconciliatedJournalEntryAccordion from './ReconciliatedJournalEntryAccordion';
import ReconciliatedStatementAccordion from './ReconciliatedStatementAccordion';
import ReconciliationJournalEntriesPane from './ReconciliationJournalEntriesPane';
import ReconciliationStatementsPane from './ReconciliationStatementsPane';
import ReconciliationCheckedPane from './ReconciliationCheckedPane';
import UploadStatementPane from './UploadStatementPane';
import { toastNotification } from '../../shared/toastNotification';
import StatementTable from './StatementTable';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  SwipeableDrawer,
} from '@mui/material';
import ReconciliationFilterBar from './ReconciliationFilterBar';
import DuplicatedFitIdsDialog from './Dialogs/DuplicatedFitIdsDialog';
import DuplicatedStatementsDialog from './Dialogs/DuplicatedStatementsDialog';
import { set } from 'date-fns';

const ReconciliationPage = () => {
  const [files, setFiles] = useState([]);
  const [fileRejections, setFileRejections] = useState([]);
  const [statement, setStatement] = useState('');
  const [dateSorting, setDateSorting] = useState('asc');
  const [valueSorting, setValueSorting] = useState('none');
  const [statementDateSorting, setStatementDateSorting] = useState('asc');
  const [statementValueSorting, setStatementValueSorting] = useState('none');
  const [reconciliatedDateSorting, setReconciliatedDateSorting] =
    useState('asc');
  const [reconciliatedValueSorting, setReconciliatedValueSorting] =
    useState('none');
  const [
    statementReconciliatedDateSorting,
    setStatementReconciliatedDateSorting,
  ] = useState('asc');
  const [
    statementReconciliatedValueSorting,
    setStatementReconciliatedValueSorting,
  ] = useState('none');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [
    isImportStatementConfirmationDialogOpen,
    setIsImportStatementConfirmationDialogOpen,
  ] = useState(false);
  const [duplicatedFitIdsDialogOpen, setDuplicatedFitIdsDialogOpen] =
    useState(false);
  const [tempData, setTempData] = useState({});

  const dispatch = useDispatch();

  const companyId = localStorage.getItem('companyId');

  const {
    startReconciliationDate,
    endReconciliationDate,
    filterType,
    entryList,
    statementList,
    currentStatement,
    myStatementList,
    selectedBank,
    uploadedStatement,
  } = useSelector((state) => state.reconciliationValues);

  const { data: banks = [], isLoading: isLoadingBanks } = useQueryBanks(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const {
    data: entriesReconciliation,
    isLoading: isLoadingEntriesReconciliation,
    isValidating: isValidatingEntriesReconciliation,
  } = useQueryEntriesReconciliation(
    // company.data.id,
    companyId,
    startReconciliationDate,
    endReconciliationDate,
    filterType,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: statementsReconciliation,
    isLoading: isLoadingStatementsReconciliation,
    isValidating: isValidatingStatementsReconciliation,
  } = useQueryStatements(
    // company.data.id,
    companyId,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );

  const handleChange = (files) => {
    const fileExtention = files[0].name.split('.').pop();
    if (fileExtention !== 'ofx') {
      alert('O arquivo deve ser do tipo .ofx!');
    } else {
      setFiles([files[0]]);
      handleFileChange(files[0]);
    }
  };

  const handleRejected = useCallback(
    (fileRejections) => setFileRejections([fileRejections[0]]),
    []
  );

  const handleRemove = useCallback(() => {
    setFiles([]);
    setFileRejections([]);
    dispatch(reconciliationValuesActions.resetFilter());
    setIsImportStatementConfirmationDialogOpen(false);
    setDuplicatedFitIdsDialogOpen(false);
  }, []);

  const findDuplicateFitIds = (transactions) => {
    const fitIdSet = new Set();
    const duplicateFitIds = new Set();

    transactions.forEach((transaction) => {
      const fitId = transaction.FITID;
      if (fitIdSet.has(fitId)) {
        duplicateFitIds.add(fitId);
      } else {
        fitIdSet.add(fitId);
      }
    });

    return Array.from(duplicateFitIds);
  };

  const handleFileChange = (e) => {
    const reader = new FileReader();
    const file = e;

    reader.onload = () => {
      const data = reader.result;
      const parsedData = parseOFXToJSON(data);
      const duplicateFitIds = findDuplicateFitIds(parsedData.data.transactions);

      if (duplicateFitIds.length > 0) {
        console.log('duplicateFitIds', duplicateFitIds);
        setDuplicatedFitIdsDialogOpen(true);
      }
      console.log('parsedData', parsedData);
      setTempData({
        companyId: companyId,
        statementObject: parsedData,
        isUploaded: true,
      });
      if (
        myStatementList.some(
          (statement) =>
            statement.statementObject.data.bankMsg.DTSTART ===
              parsedData.data.bankMsg.DTSTART &&
            statement.statementObject.data.bankMsg.DTEND ===
              parsedData.data.bankMsg.DTEND &&
            statement.statementObject.data.statement.ACCTID ===
              parsedData.data.statement.ACCTID
        )
      ) {
        setIsImportStatementConfirmationDialogOpen(true);
      } else {
        dispatch(reconciliationValuesActions.setCurrentStatement(null));
        dispatch(
          reconciliationValuesActions.setUploadedStatement({
            companyId: companyId,
            statementObject: parsedData,
            isUploaded: true,
          })
        );
      }
    };

    reader.onerror = (err) => {
      console.error('Error reading file:', err);
    };

    reader.readAsText(file, 'ISO-8859-1');
  };

  const handleConfirmImport = () => {
    dispatch(reconciliationValuesActions.setCurrentStatement(null));
    dispatch(reconciliationValuesActions.setUploadedStatement(tempData));
    setIsImportStatementConfirmationDialogOpen(false);
  };

  const handleDateSorting = () => {
    if (dateSorting === 'asc') {
      setDateSorting('desc');
    } else if (dateSorting === 'desc') {
      setDateSorting('none');
    } else if (dateSorting === 'none') {
      setDateSorting('asc');
    }
  };
  const handleValueSorting = () => {
    if (valueSorting === 'asc') {
      setValueSorting('desc');
    } else if (valueSorting === 'desc') {
      setValueSorting('none');
    } else if (valueSorting === 'none') {
      setValueSorting('asc');
    }
  };
  const handleReconciliatedDateSorting = () => {
    if (reconciliatedDateSorting === 'asc') {
      setReconciliatedDateSorting('desc');
    } else if (reconciliatedDateSorting === 'desc') {
      setReconciliatedDateSorting('none');
    } else if (reconciliatedDateSorting === 'none') {
      setReconciliatedDateSorting('asc');
    }
  };
  const handleReconciliatedValueSorting = () => {
    if (reconciliatedValueSorting === 'asc') {
      setReconciliatedValueSorting('desc');
    } else if (reconciliatedValueSorting === 'desc') {
      setReconciliatedValueSorting('none');
    } else if (reconciliatedValueSorting === 'none') {
      setReconciliatedValueSorting('asc');
    }
  };
  const handleStatementDateSorting = () => {
    if (statementDateSorting === 'asc') {
      setStatementDateSorting('desc');
    } else if (statementDateSorting === 'desc') {
      setStatementDateSorting('none');
    } else if (statementDateSorting === 'none') {
      setStatementDateSorting('asc');
    }
  };
  const handleStatementValueSorting = () => {
    if (statementValueSorting === 'asc') {
      setStatementValueSorting('desc');
    } else if (statementValueSorting === 'desc') {
      setStatementValueSorting('none');
    } else if (statementValueSorting === 'none') {
      setStatementValueSorting('asc');
    }
  };
  const handleStatementReconciliatedDateSorting = () => {
    if (statementReconciliatedDateSorting === 'asc') {
      setStatementReconciliatedDateSorting('desc');
    } else if (statementReconciliatedDateSorting === 'desc') {
      setStatementReconciliatedDateSorting('none');
    } else if (statementReconciliatedDateSorting === 'none') {
      setStatementReconciliatedDateSorting('asc');
    }
  };
  const handleStatementReconciliatedValueSorting = () => {
    if (statementReconciliatedValueSorting === 'asc') {
      setStatementReconciliatedValueSorting('desc');
    } else if (statementReconciliatedValueSorting === 'desc') {
      setStatementReconciliatedValueSorting('none');
    } else if (statementReconciliatedValueSorting === 'none') {
      setStatementReconciliatedValueSorting('asc');
    }
  };

  const isValueEqual = () => {
    if (entryList.length === 0 || statementList.length === 0) return;
    let entryListSum = entryList.reduce(
      (total, entry) => total + entry.valueFinal,
      0
    );
    let statementListSum = statementList.reduce(
      (total, statement) => total + Math.abs(Number(statement.TRNAMT)),
      0
    );
    console.log('entryListSum é igual?', entryListSum === statementListSum);
    if (entryListSum === statementListSum) return true;
    let difference = entryListSum - statementListSum;
    if (entryListSum > statementListSum) {
      console.log('entryListSum > statementListSum');
      if (difference < 0.0001) {
        difference = 0;
        entryListSum = statementListSum;
      }
    }
    if (entryListSum < statementListSum) {
      console.log('entryListSum < statementListSum');
      if (difference > -0.0001) {
        entryListSum = statementListSum;
        difference = 0;
      }
    }
    if (entryList.length > 0 && statementList.length > 0) {
      let entryTotal = 0;
      entryList.forEach((entry) => {
        entryTotal += entry.valueFinal;
      });

      if (parseFloat(entryListSum) !== parseFloat(statementListSum)) {
        let statusResponse = 'warning';
        let toastContent = 'Os valores não coincidem!';
        toastNotification(statusResponse, toastContent);
        return false;
      }
      return true;
    }
  };

  const handleReconciliation = () => {
    let statusResponse = false;
    let toastContent = '';
    console.log('handleReconciliation');
    console.log('isValueEqual?', isValueEqual());
    if (isValueEqual()) {
      const tempEntryList = [...entryList];
      console.log(tempEntryList);
      const updatedEntries = tempEntryList.map((entry) => {
        // console.log(entry.dateVencimento);
        // console.log(parseStatementDateToFetch(statementList[0].DTPOSTED));

        // Create a new object with modified properties
        return {
          ...entry,
          // dateCompetencia:
          //   new Date(entry.dateCompetencia) < new Date(entry.dateVencimento)
          //     ? entry.dateCompetencia
          //     : entry.dateVencimento,
          dateVencimento: parseStatementDateToFetch(statementList[0].DTPOSTED),
          fitId: statementList[0].FITID,
          statusPayment: true,
        };
      });
      console.log(updatedEntries);
      putManyEntry(updatedEntries)
        .then((res) => {
          console.log(res);
          statusResponse = 'success';
          toastContent = 'Conciliação realizada com sucesso!';
          dispatch(reconciliationValuesActions.setEntryList([]));
          dispatch(reconciliationValuesActions.setStatementList([]));
        })
        .catch((err) => {
          statusResponse = 'warning';
          console.log('post err', err);
          toastContent = `Verifique os campos novamente.`;
        })
        .finally(() => {
          toastNotification(statusResponse, toastContent);
          mutate('entriesReconciliation');
        });
    }
  };

  const saveStatement = (statement) => {
    let statusResponse = false;
    let toastContent = '';
    console.log('SaveStatement', statement);
    let statementObject = {
      companyId: statement.companyId,
      bankId: statement.bankId,
      bankName: statement.bankName,
      statementObject: JSON.stringify(statement.statementObject),
      totalStatements: statement.statementObject.data.transactions.length,
    };
    console.log('StatementObject', statementObject);
    postStatement(statementObject)
      .then((res) => {
        statusResponse = 'success';
        toastContent = 'Extrato salvo com sucesso!';
        console.log(res);
        dispatch(
          reconciliationValuesActions.setCurrentStatement({
            ...statementObject,
            bankId: selectedBank,
            bankName: banks.data.find((bank) => bank.id === selectedBank)?.name,
            isUploaded: false,
            id: res.data.id,
            statementObject: JSON.parse(statementObject.statementObject),
          })
        );
        dispatch(reconciliationValuesActions.setUploadedStatement(null));
        mutate('statements');
        setFiles([]);
        setFileRejections([]);
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent = `Erro ao salvar extrato. Tente novamente.`;
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
      });
  };

  const parseStatement = (statementList) => {
    const tempStatementList = [];
    // const finalStatementList = [];
    statementList.forEach((item) => {
      tempStatementList.push({
        ...item,
        statementObject: JSON.parse(item.statementObject),
      });
    });
    console.log(tempStatementList);

    tempStatementList.sort((a, b) => {
      // Compare date
      const dateA = parseStatementDateToDisplay(
        a.statementObject.data.bankMsg.DTSTART
      );
      const dateB = parseStatementDateToDisplay(
        b.statementObject.data.bankMsg.DTSTART
      );

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;

      // If dates are equal, compare bankName
      if (a.bankName < b.bankName) return -1;
      if (a.bankName > b.bankName) return 1;

      return 0; // Objects are equal
    });
    dispatch(reconciliationValuesActions.setMyStatementList(tempStatementList));
    console.log(statementsReconciliation);
  };

  const handleReconciliatedCounter = () => {
    const tempList = currentStatement.statementObject.data.transactions.filter(
      (statement) =>
        entriesReconciliation.data.some(
          (entry) =>
            entry.fitId === statement.FITID &&
            entry.bankAccountId === currentStatement.bankId
        )
    );
    console.log('tempList', tempList);
    dispatch(
      reconciliationValuesActions.setStatementReconciliatedList(tempList)
    );
  };

  useEffect(() => {
    !isValidatingStatementsReconciliation &&
      !isLoadingStatementsReconciliation &&
      parseStatement(statementsReconciliation.data);
  }, [isValidatingStatementsReconciliation]);

  useEffect(() => {
    if (currentStatement) {
      // get the list of dates from the statement
      const tempList = currentStatement.statementObject.data.transactions.map(
        (statement) => {
          return parseStatementDateToDisplay(statement.DTPOSTED);
        }
      );

      const { oldest, mostRecent } = findOldestAndMostRecentDates(tempList);

      // Take the oldest date and change it to the first day of the month and year
      // and the most recent date and change it to the last day of the month and year

      const firstDay = new Date(oldest.getFullYear(), oldest.getMonth(), 1);
      const lastDay = new Date(
        mostRecent.getFullYear(),
        mostRecent.getMonth() + 1,
        0
      );

      console.log({
        firstDay,
        lastDay,
        oldest: oldest.getFullYear(),
        mostRecent: mostRecent.getFullYear(),
      });

      const startDate = firstDay.toISOString().substring(0, 10);
      const endDate = lastDay.toISOString().substring(0, 10);
      dispatch(
        reconciliationValuesActions.setReconciliationStartDate(startDate)
      );
      dispatch(reconciliationValuesActions.setReconciliationEndDate(endDate));
      // console.log(currentStatement.statementObject);
    }
  }, [currentStatement]);

  useEffect(() => {
    mutate('entriesReconciliation');
  }, [endReconciliationDate]);

  useEffect(() => {
    mutate('statements');
  }, []);

  useEffect(() => {
    if (!isValidatingStatementsReconciliation && currentStatement) {
      const tempStatement = statementsReconciliation.data.find(
        (statement) => statement.id === currentStatement.id
      );
      // console.log('currentStatement 400', currentStatement);
      // console.log('tempStatement 400', tempStatement);
      // console.log(
      //   'statementsReconciliation 400',
      //   statementsReconciliation.data
      // );
      dispatch(
        reconciliationValuesActions.setCurrentStatement({
          ...tempStatement,
          statementObject: JSON.parse(tempStatement.statementObject),
          isUploaded: false,
        })
      );
    }
  }, [isValidatingStatementsReconciliation, statementsReconciliation]);

  useEffect(() => {
    entriesReconciliation &&
      currentStatement &&
      currentStatement.statementObject &&
      handleReconciliatedCounter();
  }, [isValidatingEntriesReconciliation, currentStatement]);
  return (
    <Pane width='100%'>
      <DuplicatedStatementsDialog
        isImportStatementConfirmationDialogOpen={
          isImportStatementConfirmationDialogOpen
        }
        handleRemove={handleRemove}
        handleConfirmImport={handleConfirmImport}
      />
      <DuplicatedFitIdsDialog
        duplicatedFitIdsDialogOpen={duplicatedFitIdsDialogOpen}
        setDuplicatedFitIdsDialogOpen={setDuplicatedFitIdsDialogOpen}
        handleRemove={handleRemove}
      />
      <Pane>
        <Pane
          display='flex'
          marginTop={15}
          marginBottom={15}
          justifyContent='center'
        >
          <Heading
            fontSize='3.5vmin'
            fontWeight='bold'
            color='rgba(81, 81, 81,0.8)'
          >
            Conciliação Bancária (Beta)
          </Heading>
        </Pane>
        <Pane>
          {uploadedStatement && uploadedStatement.isUploaded && (
            <ReconciliationFilterBar
              isLoadingBanks={isLoadingBanks}
              banks={banks}
              statement={uploadedStatement}
              saveStatement={saveStatement}
              setIsDrawerOpen={setIsDrawerOpen}
              entriesReconciliation={entriesReconciliation}
            />
          )}
          {currentStatement && currentStatement.isUploaded === false && (
            <Pane>
              <ReconciliationFilterBar
                isLoadingBanks={isLoadingBanks}
                banks={banks}
                statement={currentStatement}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </Pane>
          )}
        </Pane>
      </Pane>
      <Pane
        display='flex'
        flexDirection='column'
        alignItems='center'
        overflowY='scroll'
        height='74%'
      >
        <Pane display='flex'>
          {!uploadedStatement && !currentStatement && (
            <Pane display='flex' flexDirection='column'>
              <Heading marginBottom={20} marginTop={40} size={400}>
                Selecione ou importe um extrato no botão abaixo.
              </Heading>
              <Button size='large' onClick={() => setIsDrawerOpen(true)}>
                Painel de Extratos
              </Button>
            </Pane>
          )}
          <SwipeableDrawer
            anchor='right'
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            onOpen={() => setIsDrawerOpen(true)}
            PaperProps={{ sx: { bgcolor: '#d3d6e2' } }}
          >
            <Pane width={520}>
              <Pane display='flex' justifyContent='end'>
                <IconButton
                  icon={CrossIcon}
                  appearance='minimal'
                  size='large'
                  onClick={() => setIsDrawerOpen(false)}
                />
              </Pane>
              <UploadStatementPane
                handleChange={handleChange}
                handleRejected={handleRejected}
                handleRemove={handleRemove}
                files={files}
                fileRejections={fileRejections}
              />
              <Divider />
              {files.length < 1 && statementsReconciliation && (
                <Pane>
                  <StatementTable
                    statementsReconciliation={statementsReconciliation}
                    entriesReconciliation={entriesReconciliation}
                    setIsDrawerOpen={setIsDrawerOpen}
                  />
                </Pane>
              )}
            </Pane>
          </SwipeableDrawer>
        </Pane>

        {currentStatement && currentStatement.bankId && (
          <Pane width='100%'>
            <Pane
              className='reconciliation-main-pane'
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              // marginX={10}
              marginY={10}
              borderRadius={10}
              // paddingY={10}
              paddingX={5}
              // backgroundColor='#c9ccd8'
            >
              <Heading marginY={10} size={700} textAlign='center'>
                Painel de Conciliação
              </Heading>
              <Pane display='flex'>
                <ReconciliationJournalEntriesPane
                  handleDateSorting={handleDateSorting}
                  dateSorting={dateSorting}
                  handleValueSorting={handleValueSorting}
                  valueSorting={valueSorting}
                  isLoadingEntriesReconciliation={
                    isLoadingEntriesReconciliation
                  }
                  isValidatingEntriesReconciliation={
                    isValidatingEntriesReconciliation
                  }
                  entriesReconciliation={entriesReconciliation}
                  currentStatement={currentStatement}
                />
                <ReconciliationCheckedPane
                  entryList={entryList}
                  statementList={statementList}
                  handleReconciliation={handleReconciliation}
                />
                <ReconciliationStatementsPane
                  handleStatementDateSorting={handleStatementDateSorting}
                  statementDateSorting={statementDateSorting}
                  handleStatementValueSorting={handleStatementValueSorting}
                  statementValueSorting={statementValueSorting}
                  currentStatement={currentStatement}
                  entriesReconciliation={entriesReconciliation}
                  findDuplicateFitIds={findDuplicateFitIds}
                />
              </Pane>
            </Pane>

            {currentStatement && (
              <Pane height='300px'>
                <Divider sx={{ marginY: '10px' }} />
                <Pane display='flex' justifyContent='space-evenly'>
                  <ReconciliatedJournalEntryAccordion
                    entriesReconciliation={entriesReconciliation}
                    currentStatement={currentStatement}
                    reconciliatedDateSorting={reconciliatedDateSorting}
                    reconciliatedValueSorting={reconciliatedValueSorting}
                    handleReconciliatedDateSorting={
                      handleReconciliatedDateSorting
                    }
                    handleReconciliatedValueSorting={
                      handleReconciliatedValueSorting
                    }
                  />
                  <ReconciliatedStatementAccordion
                    entriesReconciliation={entriesReconciliation}
                    currentStatement={currentStatement}
                    statementReconciliatedDateSorting={
                      statementReconciliatedDateSorting
                    }
                    statementReconciliatedValueSorting={
                      statementReconciliatedValueSorting
                    }
                    handleStatementReconciliatedDateSorting={
                      handleStatementReconciliatedDateSorting
                    }
                    handleStatementReconciliatedValueSorting={
                      handleStatementReconciliatedValueSorting
                    }
                  />
                </Pane>
              </Pane>
            )}
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};

export default ReconciliationPage;
