import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from 'evergreen-ui';
import React from 'react';

const DuplicatedFitIdsDialog = ({
  duplicatedFitIdsDialogOpen,
  setDuplicatedFitIdsDialogOpen,
  handleRemove,
}) => {
  const handleClose = () => {
    setDuplicatedFitIdsDialogOpen(false);
  };
  return (
    <div>
      <Dialog open={duplicatedFitIdsDialogOpen} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>Atenção</DialogTitle>
          <DialogContentText>
            Encontramos lançamentos com o mesmo identificador único devido a
            algum erro na geração do extrato pelo banco, por isso estes
            lançamentos não poderão ser conciliados. Deseja importar mesmo
            assim?
          </DialogContentText>
          <DialogActions>
            <Button appearance='primary' intent='danger' onClick={handleRemove}>
              Cancelar
            </Button>
            <Button
              appearance='primary'
              intent='success'
              autoFocus
              onClick={handleClose}
            >
              Importar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DuplicatedFitIdsDialog;
